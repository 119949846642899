import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function RedirectUrl() {
  const { urlValue } = useParams();

  const getUrl = async () => {
    await axios
      .get(`https://seds.searcheducation.com/api/${urlValue}`)
      .then((res) => {
        window.location.href = res.data.Result;
      });
  };
  useEffect(() => {
    getUrl();
  });
}
